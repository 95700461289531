html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: FontAwesome;
  src: url("../assets/webfonts/font-awesome/fontawesome-webfont.eot");
  src: url("../assets/webfonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../assets/webfonts/font-awesome/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../assets/webfonts/font-awesome/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../assets/webfonts/font-awesome/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../assets/webfonts/font-awesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa:before {
  font: 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.fa-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  text-align: center;
  width: 1.28571em;
}

.fa-ul {
  margin-left: 2.14286em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2.14286em;
  position: absolute;
  top: .142857em;
  left: -2.14286em;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: 2s linear infinite fa-spin;
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  -webkit-animation: 1s steps(8, end) infinite fa-spin;
  animation: 1s steps(8, end) infinite fa-spin;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before, .fa-users:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-copy:before, .fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before, .fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before, .fa-sort:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-legal:before, .fa-gavel:before {
  content: "";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before, .fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before, .fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before, .fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before, .fa-usd:before {
  content: "";
}

.fa-rupee:before, .fa-inr:before {
  content: "";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "";
}

.fa-won:before, .fa-krw:before {
  content: "";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
  content: "";
}

.fa-ge:before, .fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before, .fa-weixin:before {
  content: "";
}

.fa-send:before, .fa-paper-plane:before {
  content: "";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before, .fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before, .fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before, .fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before, .fa-battery:before, .fa-battery-full:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before, .fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before, .fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "";
}

.fa-handshake-o:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-o:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-book-o:before {
  content: "";
}

.fa-vcard:before, .fa-address-card:before {
  content: "";
}

.fa-vcard-o:before, .fa-address-card-o:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-circle-o:before {
  content: "";
}

.fa-user-o:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-thermometer-4:before, .fa-thermometer:before, .fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-bathtub:before, .fa-s15:before, .fa-bath:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-eercast:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-snowflake-o:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("../assets/webfonts/slick/slick.eot");
  src: url("../assets/webfonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../assets/webfonts/slick/slick.woff") format("woff"), url("../assets/webfonts/slick/slick.ttf") format("truetype"), url("../assets/webfonts/slick/slick.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  opacity: .25;
  color: #000;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue.eot?#iefix");
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue.eot?#iefix") format("eot"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue.woff2") format("woff2"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue.woff") format("woff"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue.ttf") format("truetype");
}

@font-face {
  font-family: HelveticaNeue-Bold;
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.eot?#iefix");
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.eot?#iefix") format("eot"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.woff2") format("woff2"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.woff") format("woff"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: HelveticaNeue-Italic;
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.eot?#iefix");
  src: url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.eot?#iefix") format("eot"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.woff2") format("woff2"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.woff") format("woff"), url("../../assets/webfonts/helveticaNeue/HelveticaNeue-Italic.ttf") format("truetype");
}

@font-face {
  font-family: Maxx-Regular;
  src: url("../../assets/webfonts/Maxx/Maxx-Regular.ttf");
  src: url("../../assets/webfonts/Maxx/Maxx-Regular.ttf") format("truetype"), url("../../assets/webfonts/Maxx/Maxx-Regular.otf") format("otf"), url("../../assets/webfonts/Maxx/Maxx-Regular2.otf") format("otf");
}

@font-face {
  font-family: Maxx-Light;
  src: url("../../assets/webfonts/Maxx/Maxx-Light.ttf");
  src: url("../../assets/webfonts/Maxx/Maxx-Light.ttf") format("truetype"), url("../../assets/webfonts/Maxx/Maxx-Light.otf") format("otf"), url("../../assets/webfonts/Maxx/Maxx-Light2.otf") format("otf");
}

@font-face {
  font-family: Maxx-Medium;
  src: url("../../assets/webfonts/Maxx/Maxx-Medium.ttf");
  src: url("../../assets/webfonts/Maxx/Maxx-Medium.ttf") format("truetype"), url("../../assets/webfonts/Maxx/Maxx-Medium.otf") format("otf"), url("../../assets/webfonts/Maxx/Maxx-Medium2.otf") format("otf");
}

@font-face {
  font-family: Maxx-Bold;
  src: url("../../assets/webfonts/Maxx/Maxx-Bold.ttf");
  src: url("../../assets/webfonts/Maxx/Maxx-Bold.ttf") format("truetype"), url("../../assets/webfonts/Maxx/Maxx-Bold.otf") format("otf"), url("../../assets/webfonts/Maxx/Maxx-Bold2.otf") format("otf");
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: none;
  }

  1% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: none;
  }

  1% {
    opacity: 0;
    display: block;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.your-selector {
  opacity: 0;
  transition: all 1s cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.animated-block.your-selector, .animated-block .your-selector {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes pulseAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulseAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }
}

@keyframes bounce {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }
}

@-webkit-keyframes bounceLeft {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  45% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }
}

@keyframes bounceLeft {
  from, 10%, 26.5%, 40%, 50%, to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 21.5% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  35% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  45% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0);
  }
}

.bounceLeft {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: bounceLeft;
  animation-name: bounceLeft;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  font-family: Maxx-Light, HelveticaNeue, helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width <= 1000px) {
  body {
    font-size: 1.4rem;
    line-height: 20px;
    overflow-x: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
  margin: 0;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-weight: normal;
}

h2 {
  font-size: 4.5rem;
  line-height: 54px;
}

@media only screen and (width <= 1000px) {
  h2 {
    font-size: 3rem;
    line-height: 35px;
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 29px;
}

@media only screen and (width <= 1000px) {
  h3 {
    font-size: 2rem;
    line-height: 23px;
  }
}

p {
  margin-bottom: 1.5em;
}

b, strong {
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
}

dfn, cite, em, i {
  font-style: italic;
}

sup, sub {
  height: 0;
  padding-left: 3px;
  font-size: 1.2rem;
  line-height: 0;
  position: relative;
}

sup {
  top: -1rem;
}

a {
  color: #c8102e;
  text-decoration: none;
}

a:visited {
  color: #c8102e;
}

a:hover, a:focus, a:active {
  color: #c8102e;
  outline: 0;
  text-decoration: underline;
}

p a {
  text-decoration: underline;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul li, ol li {
  margin-left: 15px;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
}

dd {
  margin: 0 1.5em 1.5em;
}

hr {
  background-color: #cbcbcb;
  border: 0;
  width: 100%;
  height: 1px;
  margin: 1.5em 0;
}

img {
  border: 0;
  height: auto;
}

.ctn-img img {
  width: 100%;
}

@media only screen and (width <= 1000px) {
  img {
    max-width: 100%;
  }
}

figure {
  margin: 0;
}

table {
  border-spacing: 0;
  border-collapse: separate;
  padding: 0;
}

.ctn-main {
  width: 1440px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.ctn-main.-small {
  width: 940px;
}

.ctn-main.-large {
  width: 1130px;
}

.ctn-main.-slider-ctn {
  width: 1015px;
}

.video-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.video-container iframe {
  width: 100%;
  height: 612px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 426px) {
  .video-container iframe {
    height: 100%;
  }
}

.-block {
  clear: both;
  display: block;
}

.-text-centered {
  text-align: center;
}

.-v-align {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.-alignleft {
  float: left;
  margin-right: 1.5em;
  display: inline;
}

.-alignright {
  float: right;
  margin-left: 1.5em;
  display: inline;
}

.-valign {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.-centered, .slick-slider .slick-dots {
  margin: 0 auto;
  display: table;
}

.-capitalize :first-letter {
  text-transform: uppercase;
}

.-hide, .-hidden {
  display: none !important;
}

.-desktop-only {
  display: block;
}

@media only screen and (width <= 767px) {
  .-desktop-only {
    display: none;
  }
}

.-mobile-only {
  display: none !important;
}

@media only screen and (width <= 767px) {
  .-mobile-only {
    display: block !important;
  }
}

.-colored {
  color: #c8102e;
}

.-footnote {
  font-size: 1.4rem;
  font-style: italic;
}

.button {
  font-size: 1.4rem;
  line-height: 17px;
}

.button, .button:visited {
  cursor: pointer;
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  background-color: #c8102e;
  border: 1px solid #0000;
  padding: 20px 36px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 16px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

@media only screen and (width <= 1000px) {
  .button, .button:visited {
    padding: 20px 25px;
    font-size: 1.3rem;
    line-height: 13px;
  }
}

.button:active, .button:hover {
  color: #fff;
  background-color: #f05474;
  border: 1px solid #f05474;
  text-decoration: none;
}

@media only screen and (width <= 1000px) {
  .button {
    font-size: 1.2rem;
    line-height: 14px;
  }
}

.infinite-scroll .paging-navigation, .infinite-scroll.neverending .site-footer {
  display: none;
}

.infinity-end.neverending .site-footer {
  display: block;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
  z-index: 100000;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: 5px;
  left: 5px;
  box-shadow: 0 0 2px 2px #c80000e6;
  clip: auto !important;
}

.site-body, .site-content {
  position: relative;
}

.col-ctn {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
}

.col-ctn + .button {
  margin-top: 20px;
}

@media only screen and (width <= 1000px) {
  .col-ctn + .button {
    margin-top: 0;
  }
}

.col-1-3 {
  width: calc(33.3333% - 10px);
}

.col-2-3 {
  width: calc(66.6667% - 10px);
}

.col-1-2 {
  width: calc(50% - 10px);
}

.col-1-4 {
  width: calc(25% - 10px);
}

.col-3-4 {
  width: calc(75% - 10px);
}

.col-1-5 {
  width: calc(20% - 10px);
}

.col-2-5 {
  width: calc(40% - 15px);
}

.col-3-5 {
  width: calc(60% - 15px);
}

.col-slider {
  padding: 0 10px;
  display: inline-block;
}

@media only screen and (width <= 767px) {
  .col-slider {
    padding: 0;
  }

  .ctn-main > .col-ctn > .col-1-3, .ctn-main > .col-ctn > .col-2-3, .ctn-main > .col-ctn > .col-1-2, .ctn-main > .col-ctn > .col-1-4, .ctn-main > .col-ctn > .col-3-4, .ctn-main > .col-ctn > .col-1-5, .ctn-main > .col-ctn > .col-2-5, .ctn-main > .col-ctn > .col-3-5 {
    width: 100%;
  }

  .col-ctn .col-ctn {
    justify-content: space-between;
  }
}

.mobile-only {
  display: none;
}

@media only screen and (width <= 1000px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-first {
    -webkit-order: 1 !important;
    order: 1 !important;
  }
}

.navigation ul {
  margin: 0;
  list-style: none;
}

.navigation li {
  margin: 0;
}

section {
  margin-bottom: 130px;
}

@media only screen and (width <= 767px) {
  section {
    margin-bottom: 60px;
  }
}

section.main {
  margin-bottom: 0;
}

section a:not(.button) {
  text-decoration: none;
}

section a:not(.button):hover {
  color: #fff;
  background-color: #c8102e;
  text-decoration: none;
}

.subtitle {
  margin-bottom: 5px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 2rem;
  line-height: 24px;
  display: block;
}

@media only screen and (width <= 1000px) {
  .subtitle {
    font-size: 1.8rem;
    line-height: 21px;
  }
}

.subtitle + div {
  margin-top: 35px;
}

@media only screen and (width <= 1000px) {
  .subtitle + div {
    margin-top: 15px;
  }
}

.description {
  margin-top: 5px;
}

.ctn-main > .description {
  padding-right: 70px;
}

@media only screen and (width <= 1000px) {
  .ctn-main > .description {
    padding-right: 0;
  }
}

.bits-FormControl {
  box-sizing: border-box;
  vertical-align: middle;
  font: inherit;
  background: none;
  border: 1px solid;
  width: 100%;
  margin: 0;
  padding: .4em .5em;
  font-size: 100%;
  position: relative;
}

.bits-FormControl[type="search"] {
  box-sizing: border-box;
}

.bits-FormControl:disabled, .bits-FormControl.is-disabled {
  cursor: default;
}

.bits-FormControl--checkbox, .bits-FormControl--radio {
  margin-top: -1px;
  margin-top: 0\9 ;
  border: 0;
  width: auto;
  padding: 0;
}

.bits-FormControl--radio {
  margin-top: -2px;
  margin-top: 0\9 ;
}

.site-header {
  padding: 20px 0;
  font-family: Maxx-Medium;
  font-size: 1.8rem;
  line-height: 18px;
  position: relative;
}

@media only screen and (width <= 767px) {
  .site-header {
    padding-top: 48px;
    padding-bottom: 8px;
  }
}

.site-header .ctn-main {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__logo {
  cursor: pointer;
  width: 210px;
  font-size: 0;
  transition: all 1s;
}

@media only screen and (width <= 767px) {
  .site-header .site-header__logo {
    padding: 0;
  }
}

@media only screen and (width <= 420px) {
  .site-header .site-header__logo {
    width: auto;
  }
}

.site-header .site-header__logo img {
  width: 100%;
  display: block;
}

.site-header .site-header__menu .menu__toggle {
  color: #691b32;
  cursor: pointer;
  background-color: #fff;
  border: none;
  width: 34px;
  height: 34px;
  padding: 10px;
  display: none;
}

@media only screen and (width <= 767px) {
  .site-header .site-header__menu .menu__toggle {
    display: block;
  }
}

.site-header .site-header__menu .site-header__menu-ul {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  list-style: none;
  display: -webkit-flex;
  display: flex;
}

.site-header .site-header__menu .site-header__menu-ul li {
  color: #c8102e;
}

.site-header .site-header__menu .site-header__menu-ul li > a {
  cursor: pointer;
  text-decoration: underline;
}

.site-header .site-header__menu .site-header__menu-ul li > a.is-active {
  color: #000;
  text-decoration: none;
}

@media only screen and (width <= 767px) {
  .site-header .site-header__menu .site-header__menu-ul {
    background-color: #fff;
    -webkit-flex-direction: column;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    padding: 16px 0;
    display: none;
    position: absolute;
    top: 90px;
    left: 0;
  }
}

.site-header .site-header__menu .site-header__menu-ul.open {
  z-index: 100;
  display: -webkit-flex;
  display: flex;
}

.hero-banner {
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
}

@media only screen and (width <= 767px) {
  .hero-banner {
    margin-bottom: 65px;
  }
}

.hero-banner img {
  width: 100%;
  display: block;
}

.hero-banner .col-2-5.-v-align img {
  max-width: 415px;
  padding: 50px 0;
}

@media only screen and (width <= 767px) {
  .hero-banner .col-2-5.-v-align img {
    width: 100%;
    margin: 0 auto;
    padding: 50px 0 0;
  }
}

.hero-banner .ctn-main, .hero-banner .img-ctn {
  position: relative;
}

@media only screen and (width <= 1023px) {
  .hero-banner .img-ctn:before, .hero-banner .img-ctn:after {
    display: none;
  }
}

.hero-banner .video-banner-img {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.hero-banner .video-banner-img:before {
  content: "";
  z-index: 2;
  background: url("assets/img/play-btn.svg") 0 0 / 100px no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 1000px) {
  .hero-banner .video-banner-img:before {
    background-size: 80px;
    width: 80px;
    height: 80px;
  }
}

@media only screen and (width <= 767px) {
  .hero-banner .video-banner-img:before {
    background-size: 60px;
    width: 60px;
    height: 60px;
  }
}

.hero-banner .video-banner-img img {
  max-width: inherit;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-banner .tjmaxx-logo {
  z-index: 1;
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 150px !important;
}

@media only screen and (width <= 767px) {
  .hero-banner .tjmaxx-logo {
    bottom: 15px;
    right: 15px;
    width: 100px !important;
  }
}

.hero-banner .hero-banner__copy-bg {
  background-color: #c8102e;
  padding: 10px;
}

.hero-banner .hero-banner__copy {
  background-color: #fff;
}

.hero-banner .hero-banner__copy img {
  width: 290px;
}

@media only screen and (width <= 767px) {
  .hero-banner .hero-banner__copy img {
    width: 90%;
  }
}

.hero-banner .hero-banner__copy p {
  margin: 0;
  font-size: 1.6rem;
  line-height: 24px;
}

@media only screen and (width <= 1000px) {
  .hero-banner .hero-banner__copy p {
    font-size: 1.8rem;
    line-height: 25px;
  }
}

@media only screen and (width <= 767px) {
  .hero-banner .hero-banner__copy p {
    margin: 0 0 20px;
  }
}

.hero-banner .hero-banner__copy h1 {
  margin-bottom: 25px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 3.2rem;
  line-height: 45px;
}

@media only screen and (width <= 1000px) {
  .hero-banner .hero-banner__copy h1 {
    font-size: 3rem;
    line-height: 39px;
  }
}

.hero-banner .hero-banner__copy.-bottom-box {
  border: 10px solid #e2002c;
  align-items: center;
  padding: 68px 48px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 767px) {
  .hero-banner .hero-banner__copy.-bottom-box {
    display: block;
  }
}

@media only screen and (width <= 900px) {
  .hero-banner .hero-banner__copy.-bottom-box {
    padding: 25px;
  }
}

.hero-banner .hero-banner__copy.-bottom-box h1 {
  margin-right: 30px;
}

.hero-banner .hero-banner__copy.-bottom-box .button {
  text-align: center;
  align-self: center;
  width: 121px;
  padding: 15px 10px;
  display: block;
}

.full-hero-banner {
  margin: 0;
  position: relative;
}

.full-hero-banner img {
  width: 100%;
  display: block;
}

.full-hero-banner .ctn-main {
  width: auto;
  position: relative;
  padding: 0 !important;
}

.full-hero-banner .img-ctn {
  height: 612px;
  position: relative;
}

@media only screen and (width >= 426px) {
  .full-hero-banner .img-ctn {
    height: auto;
  }
}

@media only screen and (width <= 1023px) {
  .full-hero-banner .img-ctn:before, .full-hero-banner .img-ctn:after {
    display: none;
  }
}

.full-hero-banner .video-banner-img {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.full-hero-banner .video-banner-img img#videoBannerImage {
  display: none;
}

.full-hero-banner .video-banner-img img#videoBannerImageMobile {
  max-width: inherit;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media only screen and (width >= 426px) {
  .full-hero-banner .video-banner-img img#videoBannerImageMobile {
    display: none;
  }

  .full-hero-banner .video-banner-img img#videoBannerImage {
    max-width: inherit;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.full-hero-banner .hero-banner__copy-bg {
  background-color: #c8102e;
  padding: 10px;
}

.intro-section {
  background-color: #f8f8f8;
  margin-bottom: 0;
  padding: 72px 20px 64px;
}

.intro-section .ctn-main {
  width: 100%;
  max-width: 925px;
}

@media only screen and (width >= 1000px) {
  .intro-section {
    margin-bottom: 0;
    padding: 72px 150px;
  }
}

@media only screen and (width >= 767px) {
  .intro-section {
    margin-bottom: 0;
  }
}

.intro-section .intro__logo {
  pointer-events: none;
  justify-content: center;
  margin-bottom: 40px;
  display: -webkit-flex;
  display: flex;
}

.intro-section .intro {
  display: none;
}

@media only screen and (width >= 767px) {
  .intro-section .intro-mobile {
    display: none;
  }

  .intro-section .intro {
    display: block;
  }
}

.intro-section .red-text {
  color: #c8102e;
  font-family: Maxx-Bold;
}

.intro-section h2 {
  text-align: center;
  text-wrap: nowrap;
  font-family: Maxx-Bold;
  font-size: 6.1rem;
  line-height: 61px;
}

@media only screen and (width <= 767px) {
  .intro-section h2 {
    text-wrap: wrap;
    font-size: 4.8rem;
    line-height: 48px;
  }
}

.intro-section h2 span.red-text {
  font-family: Maxx-Bold;
}

.intro-section .col-ctn {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media only screen and (width <= 767px) {
  .intro-section .col-ctn {
    row-gap: 24px;
    margin-top: 32px;
    margin-bottom: 30px;
  }
}

.intro-section p {
  margin: 0;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width >= 1000px) {
  .intro-section p {
    font-size: 2rem;
    line-height: 28px;
  }
}

.intro-section h3 {
  margin-bottom: 40px;
  font-family: Maxx-Regular;
  font-size: 2.4rem;
  line-height: 29px;
}

@media only screen and (width >= 767px) {
  .intro-section h3 {
    text-align: center;
  }
}

.intro-section .--disclaimer {
  max-width: 640px;
  margin: 0 auto;
}

.intro-section .--disclaimer p {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 1.2rem;
  line-height: 1.5em;
}

.howtoclaim {
  background-color: #a6192e;
  margin-bottom: 0;
  padding: 20px 20px 56px;
}

@media only screen and (width >= 1000px) {
  .howtoclaim {
    padding: 56px;
  }
}

.howtoclaim .ctn-main {
  width: 100%;
  max-width: 1208px;
  margin: 0 auto;
}

@media only screen and (width >= 769px) {
  .howtoclaim .ctn-main {
    -webkit-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
    display: -webkit-flex;
    display: flex;
  }
}

@media only screen and (width >= 1439px) {
  .howtoclaim .ctn-main {
    column-gap: 123px;
  }
}

.howtoclaim .left-col {
  width: 100%;
}

.howtoclaim .left-col img {
  cursor: pointer;
  width: 100%;
}

.howtoclaim .left-col img#howtoclaim {
  display: none;
}

@media only screen and (width >= 1000px) {
  .howtoclaim .left-col img#howtoclaim {
    display: block;
  }

  .howtoclaim .left-col img#howtoclaim-mobile {
    display: none;
  }
}

.howtoclaim .right-col {
  text-align: left;
  color: #fff;
  margin-top: 32px;
}

@media only screen and (width >= 1000px) {
  .howtoclaim .right-col {
    margin-top: 0;
  }
}

.howtoclaim .pink-text {
  color: #fac3c1;
}

.howtoclaim h2 {
  font-family: Maxx-Bold;
  font-size: 4rem;
  line-height: 40px;
}

@media only screen and (width >= 767px) {
  .howtoclaim h2 {
    font-size: 6.1rem;
    line-height: 61px;
  }
}

.howtoclaim p {
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width >= 1000px) {
  .howtoclaim p {
    font-size: 2rem;
    line-height: 28px;
  }
}

.howtoclaim .button {
  background-color: #fff;
  border-radius: 130px;
  width: max-content;
  max-width: 292px;
  padding: 24px 32px;
}

.howtoclaim .button a {
  color: #000;
  text-wrap: nowrap;
  cursor: pointer;
  font-family: Maxx-Bold;
  font-size: 2rem;
  line-height: 22px;
}

.partnerships {
  margin-bottom: 0;
  padding-top: 50px;
}

.partnerships .ctn-main {
  width: 100%;
  max-width: 738px;
}

.partnerships h2 {
  text-align: center;
  font-family: Maxx-Bold;
  font-size: 7.2rem;
  line-height: 72px;
}

@media only screen and (width >= 767px) {
  .partnerships h2 {
    font-size: 9rem;
    line-height: 90px;
  }
}

.partnerships p {
  text-align: center;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width >= 1000px) {
  .partnerships p {
    font-size: 2rem;
    line-height: 28px;
  }
}

.partnerships .partner {
  display: none;
}

@media only screen and (width >= 767px) {
  .partnerships .partner-mobile {
    display: none;
  }

  .partnerships .partner {
    display: block;
  }
}

.partnerships .partner-top {
  margin-bottom: 40px;
}

@media only screen and (width >= 767px) {
  .partnerships .partner-top {
    margin-bottom: 80px;
  }
}

.partnerships .partner-ctn {
  color: #fff;
}

@media only screen and (width >= 767px) {
  .partnerships .partner-ctn {
    justify-content: center;
    display: -webkit-flex;
    display: flex;
  }
}

.partnerships .partner-ctn .partner-col {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 40px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width >= 1441px) {
  .partnerships .partner-ctn .partner-col {
    align-items: center;
  }
}

.partnerships .partner-ctn .partner-col img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

@media only screen and (width >= 767px) {
  .partnerships .partner-ctn .partner-col img {
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (width >= 1441px) {
  .partnerships .partner-ctn .partner-col img {
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
  }
}

.partnerships .partner-ctn .partner-col p {
  text-align: left;
  max-width: 450px;
  margin: 0;
}

@media only screen and (width >= 1000px) {
  .partnerships .partner-ctn .partner-col {
    padding: 100px;
  }
}

.partnerships .partner-ctn .partner-1 {
  background-color: #a6192e;
}

.partnerships .partner-ctn .partner-2 {
  background-color: #691b32;
}

.partnerships .partner-ctn a {
  color: #fff;
  text-decoration: underline;
}

.partnerships .partner-ctn a:hover {
  background: none;
}

.partnerships .bottom-section {
  background-color: #ffe3e0;
  padding: 60px 0;
}

.partnerships .bottom-section .partner-bottom {
  max-width: 900px;
}

@media only screen and (width >= 767px) {
  .partnerships .bottom-section .partner-bottom {
    justify-content: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
  }
}

.partnerships .bottom-section .partner-bottom h3 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Maxx-Bold;
  font-size: 4rem;
  line-height: 40px;
}

@media only screen and (width >= 767px) {
  .partnerships .bottom-section .partner-bottom h3 {
    display: none;
  }
}

.partnerships .bottom-section .partner-bottom img {
  display: none;
}

.partnerships .bottom-section .partner-bottom p {
  text-align: center;
  margin: 0;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width >= 767px) {
  .partnerships .bottom-section .partner-bottom p {
    text-align: left;
    font-size: 2rem;
    line-height: 28px;
  }

  .partnerships .bottom-section .partner-bottom img {
    max-width: 214px;
    display: block;
  }
}

.the-program .subtitle {
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
}

.the-program .col-ctn {
  margin-top: 36px;
}

.the-program p {
  margin-top: 12px;
  padding-right: 29px;
}

@media only screen and (width <= 767px) {
  .the-program p {
    padding-right: 0;
  }
}

.the-program img {
  margin-bottom: 15px;
  display: block;
}

@media only screen and (width <= 1000px) {
  .the-program img {
    max-width: 75px;
  }

  .the-program .col-1-3 {
    margin-bottom: 10px !important;
  }
}

@media only screen and (width <= 767px) {
  .the-program .col-1-3 {
    max-width: 350px;
  }
}

.the-program .-passed {
  opacity: .32;
}

.who-is-involved .col-ctn {
  margin-top: 60px;
  padding: 0 20px;
}

@media only screen and (width <= 1000px) {
  .who-is-involved .col-ctn {
    margin-top: 0;
    padding: 0;
  }
}

.who-is-involved .col-1-2 {
  width: calc(50% - 40px);
}

.who-is-involved .celeb__img-ctn {
  background: url("assets/img/celeb-striped-bg.png") center / 100% no-repeat;
  min-height: 160px;
  padding: 0 60px;
}

@media only screen and (width <= 1000px) {
  .who-is-involved .celeb__img-ctn {
    padding: 0 35px;
  }
}

.who-is-involved .celeb__img-ctn img {
  width: 100%;
}

.who-is-involved .celeb__text-ctn {
  padding: 0 60px;
  font-size: 1.6rem;
  line-height: 21px;
}

@media only screen and (width <= 1000px) {
  .who-is-involved .celeb__text-ctn {
    padding: 0 35px;
    font-size: 1.4rem;
    line-height: 21px;
  }

  .who-is-involved .celeb__text-ctn p {
    margin-top: 5px;
  }
}

.who-is-involved .hidden-name {
  width: 150px;
  margin-top: 20px;
}

@media only screen and (width <= 767px) {
  .who-is-involved .hidden-name {
    width: auto;
  }
}

.women-supporting .button {
  margin-top: 20px;
}

.women-supporting .description {
  padding-right: 12px;
}

@media only screen and (width <= 767px) {
  .women-supporting .description {
    padding-right: 0;
  }

  .women-supporting .col-3-5 {
    margin-left: -40px;
    margin-right: -60px;
    width: calc(100vw + 100px) !important;
  }
}

.writing-prompts .slide-ctn {
  align-items: center;
  min-height: 325px;
  padding: 50px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 1000px) {
  .writing-prompts .subtitle {
    text-align: left !important;
  }
}

.writing-prompts .col-slider:nth-child(3n+1) .slide-ctn {
  background: url("assets/img/prompts-frame1.jpg") center / 100% 100% no-repeat;
}

.writing-prompts .col-slider:nth-child(3n+2) .slide-ctn {
  background: url("assets/img/prompts-frame2.jpg") center / 100% 100% no-repeat;
}

.writing-prompts .col-slider:nth-child(3n+3) .slide-ctn {
  background: url("assets/img/prompts-frame3.jpg") center / 100% 100% no-repeat;
}

.writing-prompts p {
  margin: 0;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
}

@media only screen and (width <= 1000px) {
  .writing-prompts p {
    font-size: 1.8rem;
    line-height: 28px;
  }
}

.stay-connected .ctn-main {
  width: 1440px;
}

.stay-connected .ctn-main > a {
  margin-top: 30px;
}

.stay-connected img {
  max-width: 100%;
}

.stay-connected .img-ctn {
  margin-top: 40px;
}

.stay-connected .ctn-main > .col-ctn > .col-1-3, .stay-connected .ctn-main > .col-ctn > .col-1-2 {
  z-index: 1;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
  box-shadow: 0 0 4px #0000002e;
}

.stay-connected .ctn-main .description {
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width <= 1023px) {
  .stay-connected .ctn-main .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
}

.stay-connected .ctn-main > .col-ctn {
  margin-top: 75px;
  margin-bottom: 10px;
  position: relative;
}

@media only screen and (width <= 1023px) {
  .stay-connected .ctn-main > .col-ctn:before, .stay-connected .ctn-main > .col-ctn:after {
    display: none;
  }
}

.stay-connected h3 {
  font-size: 2rem;
  line-height: 28px;
}

@media only screen and (width <= 1023px) {
  .stay-connected h3 {
    margin-top: 10px;
    font-size: 1.8rem;
    line-height: 24px;
  }
}

.stay-connected p {
  font-size: 1.4rem;
  line-height: 22px;
}

@media only screen and (width <= 1023px) {
  .stay-connected p {
    margin-top: 5px;
    font-size: 1.2rem;
    line-height: 18px;
  }
}

.stay-connected .-no-radius img {
  border-radius: 0;
  width: 100%;
  max-width: 70px;
}

.stay-connected .-no-radius .empty-box {
  background-color: #c8102e;
  height: 14px;
  margin-left: auto;
  margin-right: auto;
}

.stay-connected .-no-radius .empty-box.-name {
  width: 50%;
  margin-top: 3px;
}

.stay-connected .-no-radius .empty-box.-hashtag {
  width: 70%;
  margin-top: 4px;
}

.stay-connected__bottom {
  word-wrap: break-word;
  align-self: flex-end;
  width: 100%;
  font-size: 1.2rem;
  line-height: 16px;
}

.stay-connected__bottom img {
  border-radius: 50%;
  max-width: 100px;
}

.stay-connected__date {
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 16px;
}

@media only screen and (width <= 1023px) {
  .stay-connected__date {
    font-size: 1.2rem;
  }
}

.stay-connected__video-ctn {
  cursor: pointer;
  margin: 35px 0 0;
  position: relative;
}

.stay-connected__video-ctn img {
  width: 100%;
  max-width: none;
}

.stay-connected__video-ctn:before {
  content: "";
  z-index: 2;
  cursor: pointer;
  background: url("assets/img/play-btn.svg") 0 0 / 66px no-repeat;
  width: 66px;
  height: 66px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.the-research .ctn-main {
  width: 1440px;
}

.the-research .ctn-main > .description {
  padding-right: 42px;
}

.stat-ctn {
  width: 100%;
  height: 610px;
  position: relative;
}

@media only screen and (width <= 900px) {
  .stat-ctn {
    height: 500px;
  }
}

@media only screen and (width <= 767px) {
  .stat-ctn {
    height: auto;
  }
}

.stat-item {
  position: absolute;
}

@media only screen and (width <= 767px) {
  .stat-item {
    position: relative;
  }
}

@media only screen and (width <= 1000px) {
  .stat-item img {
    width: 90%;
  }
}

@media only screen and (width <= 900px) {
  .stat-item img {
    width: 75%;
  }
}

@media only screen and (width <= 767px) {
  .stat-item img {
    width: 100%;
  }
}

.stat-item.stat-1 {
  top: 0;
  right: 55px;
}

@media only screen and (width <= 900px) {
  .stat-item.stat-1 {
    right: 0;
  }
}

@media only screen and (width <= 767px) {
  .stat-item.stat-1 {
    width: 236px;
    margin-bottom: 20px;
    left: 0;
    right: auto;
  }
}

.stat-item.stat-2 {
  top: 32px;
  left: 0;
}

@media only screen and (width <= 767px) {
  .stat-item.stat-2 {
    width: 278px;
    margin-bottom: 50px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.stat-item.stat-3 {
  bottom: 0;
  left: 47%;
}

@media only screen and (width <= 767px) {
  .stat-item.stat-3 {
    width: 190px;
    margin-bottom: 40px;
    top: 0;
    bottom: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.stat-source {
  width: 155px;
  font-size: 1.4rem;
  line-height: 22px;
  position: absolute;
  bottom: 17%;
  right: 0;
}

@media only screen and (width <= 1000px) {
  .stat-source {
    bottom: 24%;
  }
}

@media only screen and (width <= 900px) {
  .stat-source {
    font-size: 1rem;
    line-height: 16px;
  }
}

@media only screen and (width <= 767px) {
  .stat-source {
    width: 100%;
    position: relative;
  }
}

.stat-item__copy-box {
  z-index: 1;
  background-color: #fff;
  padding: 15px;
  font-size: 1.6rem;
  line-height: 22px;
  position: absolute;
  box-shadow: 0 0 4px #0000002e;
}

@media only screen and (width <= 900px) {
  .stat-item__copy-box {
    font-size: 1.2rem;
    line-height: 18px;
  }
}

.stat-1 .stat-item__copy-box {
  width: 292px;
  top: 36.5%;
  right: -16%;
}

@media only screen and (width <= 900px) {
  .stat-1 .stat-item__copy-box {
    right: 0;
  }
}

@media only screen and (width <= 767px) {
  .stat-1 .stat-item__copy-box {
    width: 210px;
    top: 30%;
    right: -23%;
  }
}

.stat-2 .stat-item__copy-box {
  width: 262px;
  bottom: -6%;
  left: 0;
}

@media only screen and (width <= 767px) {
  .stat-2 .stat-item__copy-box {
    width: 190px;
    right: -16%;
  }
}

.stat-3 .stat-item__copy-box {
  width: 188px;
  top: 46.5%;
  right: -19%;
}

@media only screen and (width <= 767px) {
  .stat-3 .stat-item__copy-box {
    width: 148px;
    right: -16%;
  }
}

.the-community .col-2-5 {
  width: calc(40% - 8px);
}

@media only screen and (width <= 767px) {
  .the-community .col-3-5 {
    margin-left: -60px;
    margin-right: -90px;
    width: calc(100vw + 150px) !important;
  }
}

.the-community img {
  max-width: 100%;
}

.get-inspired .description {
  margin-bottom: 40px;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width <= 1023px) {
  .get-inspired .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
}

.get-inspired .ctn-main > .col-ctn > .col-1-1, .get-inspired .ctn-main > .col-ctn > .col-1-2 {
  z-index: 1;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 40px 50px 15px;
  position: relative;
  box-shadow: 0 0 4px #0000002e;
}

@media only screen and (width <= 420px) {
  .get-inspired .ctn-main > .col-ctn > .col-1-1, .get-inspired .ctn-main > .col-ctn > .col-1-2 {
    padding: 30px 30px 40px;
  }
}

.get-inspired .col-1-1 {
  width: 100%;
  padding-right: 15px;
}

.get-inspired .col-1-1 .get-inspired__video-ctn {
  margin: 35px -35px 0 15px;
}

@media only screen and (width <= 767px) {
  .get-inspired .col-1-1 .col-1-2 {
    width: 100%;
  }

  .get-inspired .col-1-1 .col-1-2 .get-inspired__video-ctn {
    margin-left: -35px;
  }
}

.get-inspired span {
  display: block;
}

.get-inspired .date {
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 16px;
}

.get-inspired h3 {
  color: #c8102e;
}

.get-inspired h4 {
  margin-bottom: 10px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 20px;
}

.get-inspired p {
  font-size: 1.4rem;
  line-height: 22px;
}

.get-inspired img {
  border-radius: 50%;
}

.get-inspired .col-ctn .col-ctn {
  align-items: center;
}

.get-inspired .title {
  margin: 0;
  font-size: 1.2rem;
  line-height: 16px;
}

.get-inspired .social {
  margin-top: 15px;
  font-size: 1.2rem;
  line-height: 12px;
}

.get-inspired .button {
  margin-top: 20px;
}

.get-inspired__video-ctn {
  cursor: pointer;
  margin: 35px 0 0;
  position: relative;
}

.get-inspired__video-ctn img {
  border-radius: 0;
  width: 100%;
  max-width: none;
}

.get-inspired__video-ctn:before {
  content: "";
  z-index: 2;
  cursor: pointer;
  background: url("assets/img/play-btn.svg") 0 0 / 66px no-repeat;
  width: 66px;
  height: 66px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.col-ctn + .get-inspired__video-ctn {
  margin: 35px -35px 0;
}

.hear-from-community .slick-slider {
  margin-top: 28px;
}

.hear-from-community .slick-track {
  justify-content: flex-start;
  display: -webkit-flex;
  display: flex;
}

.hear-from-community .slick-slide {
  padding-top: 19px;
  overflow: visible;
}

.hear-from-community .slide-ctn {
  margin: 5px;
  padding: 40px 50px 16px 14px;
  position: relative;
  box-shadow: 0 0 4px #0000002e;
}

.hear-from-community .slide-ctn:before {
  content: "";
  background: url("assets/img/quote-icon.svg") center no-repeat;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hear-from-community .slide-ctn p {
  margin: 0;
  font-size: 1.4rem;
  line-height: 18px;
}

.hear-from-community .slide-ctn .author {
  margin-top: 20px;
  font-size: 1.2rem;
  line-height: 16px;
  display: block;
}

.hear-from-community .slide-ctn .author:before {
  content: "";
  background: #f68f4b;
  width: 37px;
  height: 2px;
  margin-bottom: 9px;
  display: block;
}

.hear-from-community .col-slider:nth-child(3n+1) .slide-ctn:before {
  background-color: #c8102e;
}

.hear-from-community .col-slider:nth-child(3n+2) .slide-ctn:before {
  background-color: #d33c8d;
}

.hear-from-community .col-slider:nth-child(3n+3) .slide-ctn:before {
  background-color: #ed5177;
}

.modal {
  display: none;
}

.modal.-active {
  display: block;
}

.modal.-portrait .modal__wrapper {
  width: 65%;
  height: 95vh;
}

@media only screen and (width <= 420px) {
  .modal.-portrait .modal__wrapper {
    width: 90%;
  }
}

.modal.-portrait .video-container > iframe {
  height: 85vh;
}

.modal .modal__bg {
  z-index: 3;
  cursor: pointer;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .modal__wrapper {
  z-index: 4;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  width: 78vw;
  max-height: 96vh;
  margin: 0 auto;
  padding: 70px 40px 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 9px 3px #00000042;
}

@media only screen and (width <= 1000px) {
  .modal .modal__wrapper {
    width: 95%;
    padding: 60px 25px 25px;
  }
}

.modal .modal__close-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal .modal__close-btn:before {
  content: "";
  background-image: url("assets/img/close-icon-red.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  width: 25px;
  height: 25px;
  transition: background-image .2s;
  display: block;
}

.modal .modal__close-btn:hover:before, .modal .modal__close-btn:focus:before {
  background: url("assets/img/close-icon-black.svg");
}

@media only screen and (width <= 1170px) {
  .find-your-maxx br {
    display: none;
  }
}

.find-your-maxx .ctn-main > .col-ctn {
  background-color: #a6192e;
}

.find-your-maxx .find-your-maxx__left {
  background-position: top;
  background-size: cover;
  position: relative;
}

@media only screen and (width <= 900px) {
  .find-your-maxx .find-your-maxx__left {
    min-height: 610px;
  }
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__left {
    min-height: inherit;
    height: 550px;
    margin-bottom: 10px !important;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx .find-your-maxx__left {
    height: 300px;
  }
}

.find-your-maxx .find-your-maxx__left:after, .find-your-maxx .find-your-maxx__left:before {
  content: "";
  background-image: url("assets/img/red-border2.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  transition: all .5s cubic-bezier(.215, .61, .355, 1) .4s;
  display: block;
  position: absolute;
  top: 0;
  left: 1px;
  -webkit-transform: translate(50px);
  transform: translate(50px);
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__left:after, .find-your-maxx .find-your-maxx__left:before {
    background-image: url("assets/img/red-border-bottom2.png");
    background-position: bottom;
    background-size: 100%;
    top: auto;
    bottom: -1px;
    left: 0;
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
}

.find-your-maxx .find-your-maxx__left:before {
  background-image: url("assets/img/red-border1.png");
  transition: all .5s cubic-bezier(.215, .61, .355, 1);
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__left:before {
    background-image: url("assets/img/red-border-bottom1.png");
  }
}

.find-your-maxx .find-your-maxx__left.animated-block:after, .find-your-maxx .find-your-maxx__left.animated-block:before {
  -webkit-transform: translate(0);
  transform: translate(0);
}

.find-your-maxx .find-your-maxx__right {
  color: #fff;
  padding: 46px 15px 1px 35px;
  position: relative;
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__right {
    padding: 0 15px 10px;
    margin: 0 !important;
  }
}

.find-your-maxx .find-your-maxx__right h2 {
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 30px;
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__right h2 {
    margin-bottom: 20px;
    font-size: 3.3rem;
    line-height: 40px;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx .find-your-maxx__right h2 {
    font-size: 2rem;
    line-height: 25px;
  }
}

.find-your-maxx .find-your-maxx__right p {
  font-size: 1.6rem;
  line-height: 24px;
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__right p {
    font-size: 2.5rem;
    line-height: 35px;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx .find-your-maxx__right p {
    font-size: 1.4rem;
    line-height: 20px;
  }
}

.find-your-maxx .find-your-maxx__right a, .find-your-maxx .find-your-maxx__right a:visited {
  cursor: pointer;
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  background-color: #e31c6c;
  border: 1px solid #0000;
  padding: 20px 36px;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 16px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: relative;
}

@media only screen and (width <= 1000px) {
  .find-your-maxx .find-your-maxx__right a, .find-your-maxx .find-your-maxx__right a:visited {
    padding: 20px 25px;
    font-size: 1.3rem;
    line-height: 13px;
  }
}

.find-your-maxx .find-your-maxx__right a:active, .find-your-maxx .find-your-maxx__right a:hover {
  color: #fff;
  background-color: #f05474;
  border: 1px solid #f05474;
  text-decoration: none;
}

.find-your-maxx .find-your-maxx__right .description {
  width: 125%;
  margin-top: 28px;
  font-size: 1rem;
  line-height: 12px;
  position: relative;
  left: -25%;
}

@media only screen and (width <= 767px) {
  .find-your-maxx .find-your-maxx__right .description {
    width: 100%;
    font-size: 1.5rem;
    line-height: 20px;
    left: 0;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx .find-your-maxx__right .description {
    font-size: .9rem;
    line-height: 11px;
  }
}

.find-your-maxx .positionned-logo {
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@media only screen and (width <= 767px) {
  .find-your-maxx .positionned-logo {
    width: 45%;
    top: -20px;
    left: 20px;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  .find-your-maxx .button {
    margin: 0 auto 40px;
    padding: 30px 50px;
    font-size: 2rem;
    line-height: 20px;
    display: table;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx .button {
    margin-bottom: 20px;
    padding: 18px 30px;
    font-size: 1.3rem;
    line-height: 13px;
  }
}

.find-your-maxx.--step2 .find-your-maxx__right {
  width: 33%;
  padding: 46px 65px 1px 55px;
}

@media only screen and (width <= 1170px) {
  .find-your-maxx.--step2 .find-your-maxx__right {
    padding-left: 4%;
    padding-right: 3%;
  }
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step2 .find-your-maxx__right {
    width: 100%;
    padding: 0 20px 10px;
  }
}

.find-your-maxx.--step2 .find-your-maxx__right h2 {
  padding-right: 20px;
  font-size: 2.4rem;
  line-height: 30px;
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step2 .find-your-maxx__right h2 {
    padding-right: 0;
    font-size: 3.3rem;
    line-height: 40px;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx.--step2 .find-your-maxx__right h2 {
    font-size: 1.8rem;
    line-height: 22px;
  }
}

.find-your-maxx.--step2 .find-your-maxx__right .description {
  width: 200%;
  left: -90%;
}

@media only screen and (width <= 1170px) {
  .find-your-maxx.--step2 .find-your-maxx__right .description {
    width: 170%;
    left: -70%;
  }
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step2 .find-your-maxx__right .description {
    width: 100%;
    left: 0;
  }
}

.find-your-maxx.--step3 .ctn-main > .col-ctn {
  background-color: #c8102e;
  min-height: 390px;
}

.find-your-maxx.--step3 .find-your-maxx__left {
  overflow: hidden;
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step3 .find-your-maxx__left {
    height: 410px;
  }
}

.find-your-maxx.--step3 .find-your-maxx__left:after {
  content: "";
  background-image: url("assets/img/x-mask-min2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 1px;
  -webkit-transform: translate(0)scale(1.1);
  transform: translate(0)scale(1.1);
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step3 .find-your-maxx__left:after {
    background-image: url("assets/img/x-mask-mobile.png");
    background-position: 100% 100%;
    height: calc(100% + 1px);
    left: 0;
  }
}

.find-your-maxx.--step3 .find-your-maxx__left:before {
  display: none;
}

.find-your-maxx.--step3 .find-your-maxx__left.animated-block:after {
  -webkit-transform: translate(0)scale(1);
  transform: translate(0)scale(1);
}

.find-your-maxx.--step3 .find-your-maxx__right {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateX(-25px);
  transform: translateX(-25px);
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step3 .find-your-maxx__right {
    -webkit-transform: none;
    transform: none;
  }
}

.find-your-maxx.--step3 .find-your-maxx__right h2 {
  margin-bottom: 0;
  font-size: 2.4rem;
  line-height: 36px;
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step3 .find-your-maxx__right h2 {
    margin-top: -50px;
    font-size: 3.3rem;
    line-height: 40px;
  }
}

@media only screen and (width <= 420px) {
  .find-your-maxx.--step3 .find-your-maxx__right h2 {
    font-size: 1.8rem;
    line-height: 22px;
  }
}

.find-your-maxx.--step3 .find-your-maxx__right p {
  margin-bottom: 0;
}

.find-your-maxx.--step3 .positionned-logo {
  z-index: 2;
  width: 157px;
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.find-your-maxx.--step3 .find-your-maxx__bottom {
  padding: 46px 64px 26px;
  box-shadow: 0 0 4px #0000002e;
}

@media only screen and (width <= 767px) {
  .find-your-maxx.--step3 .find-your-maxx__bottom {
    padding: 30px 20px;
  }
}

.find-your-maxx.--step3 .find-your-maxx__bottom h4 {
  font-size: 2rem;
  line-height: 36px;
}

.find-your-maxx.--step3 .find-your-maxx__bottom .description {
  font-size: 1.4rem;
  line-height: 26px;
}

@media only screen and (width <= 940px) {
  .find-your-maxx.--step3 .find-your-maxx__bottom .col-ctn {
    justify-content: center;
  }
}

.find-your-maxx.--step3 .find-your-maxx__bottom .col-1-5 {
  width: calc(20% - 45px);
}

@media only screen and (width <= 940px) {
  .find-your-maxx.--step3 .find-your-maxx__bottom .col-1-5 {
    width: 20%;
    min-width: 150px;
    padding: 0 15px 15px;
  }
}

.find-your-maxx.--step3 .find-your-maxx__bottom .col-1-5 img {
  border-radius: 50%;
  width: 100%;
}

.find-your-maxx.--step3 .find-your-maxx__bottom .col-1-5 a {
  font-size: 1.2rem;
  line-height: 16px;
}

.find-your-maxx.--step3 .find-your-maxx__bottom .col-1-5 p {
  margin: 0;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 16px;
}

.unwanted-labels {
  background-color: #f1f1f1;
  margin: 0;
  padding: 123px 0 130px;
}

@media only screen and (width <= 767px) {
  .unwanted-labels {
    padding: 92px 0 97px;
  }
}

.unwanted-labels h2 {
  color: #c8102e;
  letter-spacing: -1.95px;
  margin-bottom: 34px;
  font-size: 6.5rem;
  line-height: 65px;
}

@media only screen and (width <= 1023px) {
  .unwanted-labels h2 {
    font-size: 4.9rem;
    line-height: 49px;
  }
}

.unwanted-labels h3 {
  margin-top: 42px;
  font-size: 3.2rem;
  line-height: 30px;
}

@media only screen and (width <= 1023px) {
  .unwanted-labels h3 {
    font-size: 2.4rem;
    line-height: 22px;
  }
}

.unwanted-labels p {
  font-size: 2.4rem;
  line-height: 32px;
}

@media only screen and (width <= 1023px) {
  .unwanted-labels p {
    font-size: 1.8rem;
    line-height: 24px;
  }
}

.unwanted-labels .btn-ctn {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 12px 28px;
  margin-top: 31px;
  display: -webkit-flex;
  display: flex;
}

.unwanted-labels .btn-ctn a {
  align-items: center;
  gap: 8px 14px;
  line-height: 27px;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.unwanted-labels .col-1-2:last-child {
  margin: 0;
}

.workshops {
  margin-top: 46px;
}

.workshops h2 {
  color: #c8102e;
  letter-spacing: -2.1px;
  font-size: 7rem;
  line-height: 66px;
}

@media only screen and (width <= 1023px) {
  .workshops h2 {
    font-size: 5.2rem;
    line-height: 49px;
  }
}

.workshops .slider {
  margin: 0 -10px;
}

@media only screen and (width <= 767px) {
  .workshops .slider {
    margin: 0;
  }
}

.workshops .slick-slider {
  margin-top: 62px;
}

.workshops .col-slider {
  width: 100%;
  position: relative;
}

.workshops img {
  object-fit: cover;
  width: 100%;
  height: 553px;
}

.workshops .copy-ctn {
  background-color: #c8102e;
  min-height: 174px;
  margin: 0 10px;
  padding: 21px 29px 19px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (width <= 767px) {
  .workshops .copy-ctn {
    margin: 0;
  }
}

.workshops .copy-ctn h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2.9rem;
  line-height: 34px;
}

.workshops .copy-ctn h3:last-child {
  margin-bottom: 0;
}

.workshops .copy-ctn button {
  cursor: pointer;
  color: #fff;
  letter-spacing: 3.2px;
  text-transform: none;
  background-color: #0000;
  border: none;
  align-items: center;
  gap: 8px 14px;
  padding: 0;
  font-size: 1.3rem;
  line-height: 27px;
  display: -webkit-flex;
  display: flex;
}

.workshops .copy-ctn button img {
  width: 27px;
  height: auto;
}

.workshops .copy-ctn span {
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
  background-color: #a6192e;
  border-radius: 20px;
  margin-left: 41px;
  padding: 2px 9px 2px 12px;
  font-size: 1rem;
  line-height: 20px;
  display: inline-block;
}

.why-it-matters {
  background-color: #f8f8f8;
  margin: 0;
  padding: 70px 0;
}

.why-it-matters .col-ctn {
  align-items: center;
}

.why-it-matters .col-ctn:last-child {
  margin-top: 143px;
}

@media only screen and (width <= 767px) {
  .why-it-matters .col-ctn:last-child {
    margin-top: 107px;
  }
}

.why-it-matters .col-ctn:last-child .col-1-2:last-child {
  margin: 0;
}

.why-it-matters .copy-ctn {
  max-width: 506px;
  margin-left: auto;
  margin-right: 14%;
}

@media only screen and (width <= 767px) {
  .why-it-matters .copy-ctn {
    margin: 0;
  }
}

.why-it-matters .copy-ctn h2 {
  margin-bottom: 24px;
  font-size: 3.2rem;
  line-height: 30px;
}

@media only screen and (width <= 1023px) {
  .why-it-matters .copy-ctn h2 {
    font-size: 2.4rem;
    line-height: 22px;
  }
}

.why-it-matters .copy-ctn p {
  font-size: 2.4rem;
  line-height: 32px;
}

@media only screen and (width <= 1023px) {
  .why-it-matters .copy-ctn p {
    font-size: 1.8rem;
    line-height: 24px;
  }
}

.why-it-matters .copy-ctn p:last-child {
  margin-bottom: 0;
}

.why-it-matters .images-ctn {
  padding-left: 9%;
  position: relative;
}

.why-it-matters .images-ctn.left {
  padding-left: 0;
  padding-right: 9%;
}

.why-it-matters .images-ctn img {
  width: 100%;
}

.why-it-matters .stats-ctn {
  background-color: #f1f1f1;
  width: 64%;
  max-width: 440px;
  padding: 23% 7% 20%;
  position: absolute;
  top: 50%;
  left: -3%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: 5px 5px 50px #0000001a;
}

@media only screen and (width <= 767px) {
  .why-it-matters .stats-ctn {
    left: 0;
  }
}

.why-it-matters .stats-ctn img {
  max-width: 360px;
}

@media only screen and (width <= 767px) {
  .why-it-matters .-left .col-1-2:first-child {
    -webkit-order: 2;
    order: 2;
  }

  .why-it-matters .-left .col-1-2:last-child {
    -webkit-order: 1;
    order: 1;
  }
}

.why-it-matters .-left .copy-ctn {
  margin-left: 14%;
  margin-right: 0;
}

@media only screen and (width <= 767px) {
  .why-it-matters .-left .copy-ctn {
    margin: 0;
  }
}

.why-it-matters .-left .images-ctn {
  padding-left: 0;
  padding-right: 9%;
}

.why-it-matters .-left .stats-ctn {
  left: auto;
  right: -3%;
}

@media only screen and (width <= 767px) {
  .why-it-matters .-left .stats-ctn {
    right: 0;
  }
}

.past-initiatives {
  background-color: #f8f8f8;
  margin-bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (width <= 767px) {
  .past-initiatives {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.past-initiatives .ctn-main {
  width: 100%;
  max-width: 688px;
}

.past-initiatives h2 {
  text-align: center;
  font-family: Maxx-Bold;
  font-size: 7.2rem;
  line-height: 72px;
}

@media only screen and (width >= 767px) {
  .past-initiatives h2 {
    font-size: 9rem;
    line-height: 90px;
  }
}

.past-initiatives .past {
  display: none;
}

@media only screen and (width >= 767px) {
  .past-initiatives .past-mobile {
    display: none;
  }

  .past-initiatives .past {
    display: block;
  }
}

.past-initiatives p {
  text-align: center;
  color: #691b32;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
  line-height: 26px;
}

@media only screen and (width >= 1000px) {
  .past-initiatives p {
    color: #000;
    font-size: 2rem;
    line-height: 28px;
  }
}

.past-initiatives .slick-slider.slider-images {
  margin-bottom: 16px;
}

@media only screen and (width <= 1000px) {
  .past-initiatives .slick-slider.slider-images {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-flex;
    display: flex;
  }
}

.past-initiatives .slick-slider.slider-images .col-slider {
  width: auto;
  padding: 0 75px;
}

@media only screen and (width <= 767px) {
  .past-initiatives .slick-slider.slider-images .col-slider {
    padding: 0 20px;
  }
}

.past-initiatives .slick-slider.slider-images .col-slider img {
  object-fit: contain;
  width: calc(100vw - 40px);
  max-width: 100%;
  height: auto;
}

@media only screen and (width >= 767px) {
  .past-initiatives .slick-slider.slider-images .col-slider img {
    width: auto;
    height: 497px;
  }
}

.past-initiatives .slick-slider.slider-images .slick-slide {
  opacity: .2;
  transition: -webkit-transform .5s ease-out, transform .5s ease-out;
  -webkit-transform: scale(.9);
  transform: scale(.9);
}

.past-initiatives .slick-slider.slider-images .slick-slide.slick-current {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.past-initiatives .slick-slider.slider-images .slick-dots {
  width: auto;
  margin-top: 0;
  margin-bottom: 16px;
}

.past-initiatives .slick-slider.slider-images .slick-dots li {
  margin-top: 19px;
  margin-bottom: 19px;
}

.past-initiatives .slick-slider.slider-texts {
  width: 570px;
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (width <= 1000px) {
  .past-initiatives .slick-slider.slider-texts .texts-ctn {
    margin: 0 20px;
  }
}

.past-initiatives .slick-slider.slider-texts .col-slider {
  width: 100%;
}

.past-initiatives .arrows {
  justify-content: space-between;
  align-items: center;
  gap: 12px 32px;
  height: 0;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1001px) {
  .past-initiatives .arrows {
    right: 120px;
  }
}

.past-initiatives .arrows button {
  z-index: 1;
  position: static;
}

.past-initiatives .arrows button:hover {
  cursor: pointer;
}

.past-initiatives .texts-ctn {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.past-initiatives .texts-ctn span {
  color: #c8102e;
  font-family: Maxx-Light, HelveticaNeue, helvetica, sans-serif;
  display: block;
}

.past-initiatives .texts-ctn p {
  color: #691b32;
  margin: 0;
  padding: 16px 0 0;
  font-size: 1.6rem;
  line-height: 22px;
}

@media only screen and (width >= 1000px) {
  .past-initiatives .texts-ctn p {
    color: #000;
    font-size: 1.8rem;
    line-height: 25px;
  }
}

.disclaimer {
  background-color: #fff;
  padding: 64px 20px;
}

@media only screen and (width >= 767px) {
  .disclaimer {
    display: none;
  }
}

.disclaimer p {
  text-align: center;
  margin: 0;
  font-family: Maxx-Light;
  font-size: 1.3rem;
  line-height: 1.6em;
}

.resources-hero {
  color: #fff;
  background-color: #c8102e;
  margin-bottom: 0;
  padding: 80px 0;
}

@media only screen and (width <= 767px) {
  .resources-hero {
    padding: 64px 0;
  }
}

.resources-hero .ctn-main {
  width: 100%;
  max-width: 790px;
}

.resources-hero h2 {
  text-align: center;
  text-transform: lowercase;
  margin-bottom: 24px;
  font-family: Maxx-Bold;
  font-size: 4.8rem;
  line-height: 48px;
}

@media only screen and (width >= 1000px) {
  .resources-hero h2 {
    font-size: 6.1rem;
    line-height: 61px;
  }
}

.resources-hero p {
  text-align: center;
  margin: 0;
  font-family: Maxx-Light;
  font-size: 2.4rem;
  line-height: 29px;
}

@media only screen and (width >= 1000px) {
  .resources-hero p {
    font-size: 2.4rem;
    line-height: 28px;
  }
}

.resources-claimyour {
  color: #fff;
  background-color: #a6192e;
  margin-bottom: 0;
  padding: 80px 0;
}

@media only screen and (width <= 1000px) {
  .resources-claimyour {
    padding: 64px 0;
  }
}

.resources-claimyour .top {
  text-align: center;
  max-width: 640px;
  margin: auto;
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .top {
    margin-bottom: 56px;
  }
}

.resources-claimyour .top h2 {
  margin-bottom: 24px;
  font-family: Maxx-Bold;
  font-size: 4rem;
  line-height: 40px;
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .top h2 {
    font-size: 6.1rem;
    line-height: 61px;
  }
}

.resources-claimyour .top p {
  text-align: center;
  font-size: 1.8rem;
  line-height: 27px;
}

.resources-claimyour .bottom {
  text-align: center;
  margin: 0 auto;
}

.resources-claimyour .bottom .grid .grid__item.js-load-more-items {
  display: none;
}

.resources-claimyour .bottom .grid .grid__item.js-load-more-items.--show {
  display: block;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid .grid__item.js-load-more-items.--show {
    display: -webkit-flex;
    display: flex;
  }
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .bottom .grid {
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

.resources-claimyour .bottom .grid__item {
  background-color: #ffe3e0;
  border: 16px solid #691b32;
  margin-bottom: 24px;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item {
    align-items: center;
    display: -webkit-flex;
    display: flex;
  }
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .bottom .grid__item {
    max-width: 688px;
  }
}

.resources-claimyour .bottom .grid__item .desktop-image {
  display: none;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item .desktop-image {
    display: block;
  }

  .resources-claimyour .bottom .grid__item .mobile-image {
    display: none;
  }
}

.resources-claimyour .bottom .grid__item img.grid__image {
  object-fit: cover;
  width: 100%;
  max-width: none;
  height: 100%;
  margin-bottom: 16px;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item img.grid__image {
    width: auto;
    max-width: 35%;
    margin-bottom: 0;
  }
}

.resources-claimyour .bottom .grid__item .grid__item-details {
  padding: 0 16px 24px;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item .grid__item-details {
    padding-top: 24px;
  }
}

.resources-claimyour .bottom .grid__item .grid__item-details h3 {
  color: #e81d2c;
  text-align: left;
  margin-bottom: 16px;
  font-family: Maxx-Bold;
  font-size: 2.8rem;
  line-height: 28px;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item .grid__item-details h3 {
    font-size: 3.1rem;
    line-height: 32px;
  }
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .bottom .grid__item .grid__item-details h3 {
    font-size: 2.5rem;
    line-height: 25px;
  }
}

@media only screen and (width >= 1439px) {
  .resources-claimyour .bottom .grid__item .grid__item-details h3 {
    font-size: 3rem;
    line-height: 30px;
  }
}

.resources-claimyour .bottom .grid__item .grid__item-details p {
  text-align: left;
  color: #000;
  margin-bottom: 16px;
  font-size: 1.6rem;
  line-height: 22px;
}

.resources-claimyour .bottom .grid__item .grid__item-details p strong {
  font-family: Maxx-Medium;
}

@media only screen and (width >= 769px) {
  .resources-claimyour .bottom .grid__item .grid__item-details p {
    font-size: 1.7rem;
    line-height: 25px;
  }
}

@media only screen and (width >= 1000px) {
  .resources-claimyour .bottom .grid__item .grid__item-details p {
    font-size: 1.5rem;
    line-height: 20px;
  }
}

@media only screen and (width >= 1439px) {
  .resources-claimyour .bottom .grid__item .grid__item-details p {
    font-size: 1.7rem;
    line-height: 25px;
  }
}

.resources-claimyour .bottom .grid__item .grid__item-details .btn {
  cursor: pointer;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 80px;
  align-items: center;
  column-gap: 8px;
  padding: 16px 28px;
  font-size: 1.7rem;
  line-height: 18px;
  display: -webkit-flex;
  display: flex;
}

.resources-claimyour .bottom .grid__item .grid__item-details .btn img.play-btn-red {
  width: 14px;
  height: 18px;
}

.resources-claimyour .bottom .grid__item .grid__item-details .btn span {
  color: #888;
}

.resources-claimyour .button {
  cursor: pointer;
  text-align: center;
  text-wrap: nowrap;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  border-radius: 130px;
  width: 172px;
  height: 62px;
  padding: 22px 32px;
  font-family: Maxx-Bold;
  font-size: 1.9rem;
  line-height: 19px;
}

.resources-pastinitiative {
  color: #000;
  background-color: #f8f8f8;
  margin-bottom: 0;
  padding: 80px 56px;
}

@media only screen and (width <= 767px) {
  .resources-pastinitiative {
    padding: 64px 0 40px;
  }
}

.resources-pastinitiative .copy-ctn {
  text-align: left;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .copy-ctn {
    justify-content: space-between;
    align-items: center;
    display: -webkit-flex;
    display: flex;
  }
}

.resources-pastinitiative .copy-ctn h2 {
  margin-bottom: 27px;
  font-family: Maxx-Bold;
  font-size: 5.2rem;
  line-height: 49px;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .copy-ctn h2 {
    font-size: 6.1rem;
    line-height: 61px;
  }
}

.resources-pastinitiative .slider {
  margin: 0 -10px;
}

.resources-pastinitiative .slick-slider .desktop-image {
  display: none;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider .desktop-image {
    display: block;
  }

  .resources-pastinitiative .slick-slider .mobile-image {
    display: none;
  }
}

.resources-pastinitiative .slick-slider .slick-slide > div {
  margin: 0 10px;
}

.resources-pastinitiative .slick-slider.slider-images {
  cursor: pointer;
  margin-bottom: 16px;
}

.resources-pastinitiative .slick-slider.slider-images .col-slider {
  border: 1px solid #888;
  padding: 0;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider.slider-images .col-slider {
    align-items: start;
    max-width: 450px;
    display: -webkit-flex !important;
    display: flex !important;
  }
}

.resources-pastinitiative .slick-slider.slider-images .col-slider img.col__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 200px;
  margin-bottom: 16px;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider.slider-images .col-slider img.col__image {
    max-height: none;
    margin-bottom: 0;
  }
}

.resources-pastinitiative .slick-slider .slider__details {
  padding: 0 16px 24px;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider .slider__details {
    padding: 24px;
  }
}

.resources-pastinitiative .slick-slider .slider__details .download > a {
  color: #000;
  text-decoration: underline;
}

.resources-pastinitiative .slick-slider .slider__details h6 {
  color: #691b32;
  text-align: left;
  margin-bottom: 4px;
  font-family: Maxx-Regular;
  font-size: 1.3rem;
  line-height: 14px;
}

.resources-pastinitiative .slick-slider .slider__details h3 {
  color: #e81d2c;
  text-align: left;
  margin-bottom: 16px;
  font-family: Maxx-Bold;
  font-size: 2.8rem;
  line-height: 28px;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider .slider__details h3 {
    font-size: 3.1rem;
    line-height: 32px;
  }
}

.resources-pastinitiative .slick-slider .slider__details p {
  text-align: left;
  color: #000;
  opacity: .7;
  margin-bottom: 16px;
  font-size: 1.6rem;
  line-height: 22px;
}

.resources-pastinitiative .slick-slider .slider__details p strong {
  font-family: Maxx-Medium;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .slick-slider .slider__details p {
    font-size: 1.7rem;
    line-height: 25px;
  }
}

.resources-pastinitiative .slick-slider .slider__details .btn {
  cursor: pointer;
  color: #000;
  background-color: #fff;
  border: 1px solid #c8102e;
  border-radius: 80px;
  align-items: center;
  column-gap: 8px;
  padding: 16px 28px;
  font-size: 1.7rem;
  line-height: 18px;
  display: -webkit-flex;
  display: flex;
}

.resources-pastinitiative .slick-slider .slider__details .btn img.play-btn-red {
  width: 14px;
  height: 18px;
}

.resources-pastinitiative .slick-slider .slider__details .btn span {
  color: #888;
}

.resources-pastinitiative .arrows {
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  display: -webkit-flex;
  display: flex;
  position: relative;
}

@media only screen and (width >= 1000px) {
  .resources-pastinitiative .arrows {
    justify-content: center;
    gap: 24px;
  }
}

.resources-pastinitiative .arrows button {
  position: static;
}

.resources-pastinitiative .arrows .slick-prev {
  z-index: 1;
}

@media only screen and (width <= 1000px) {
  .resources-pastinitiative .slick-dots {
    bottom: 35px !important;
    left: 0 !important;
  }

  .resources-pastinitiative .slick-dots li {
    width: auto;
    height: auto;
    margin: 9px;
    display: inline-block;
  }

  .resources-pastinitiative .slick-dots li button {
    cursor: pointer;
    background: #fac3c1;
    border: 0;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    padding: 0;
    font-size: 0;
  }

  .resources-pastinitiative .slick-dots li button:before {
    display: none;
  }

  .resources-pastinitiative .slick-dots .slick-active button {
    pointer-events: none;
    background: #e81d2c;
  }
}

.slick-slider .slick-dots {
  z-index: 2;
  margin-top: 20px;
  list-style: none;
  position: static;
}

.slick-slider .slick-dots li {
  width: auto;
  height: auto;
  margin: 9px;
  display: inline-block;
}

.slick-slider .slick-dots li button {
  cursor: pointer;
  background: #fac3c1;
  border: 0;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  padding: 0;
  font-size: 0;
}

.slick-slider .slick-dots li button:before {
  display: none;
}

.slick-slider .slick-dots .slick-active button {
  pointer-events: none;
  background: #e81d2c;
}

.slick-arrow {
  cursor: pointer;
  width: 80px;
  height: 80px;
}

@media only screen and (width <= 1000px) {
  .slick-arrow {
    width: 48px;
    height: 48px;
  }
}

.slick-arrow:before {
  background-size: 80px;
  width: 80px;
  height: 80px;
  display: block;
}

@media only screen and (width <= 1000px) {
  .slick-arrow:before {
    background-size: 48px;
    width: 48px;
    height: 48px;
  }
}

.slick-prev:before {
  content: "";
  background-image: url("assets/img/arrows.svg");
}

.slick-next:before {
  content: "";
  -webkit-transform-origin: center;
  transform-origin: center;
  background-image: url("assets/img/arrows.svg");
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.faq > .ctn-main {
  background-color: #c8102e0d;
  padding: 50px 100px;
}

@media only screen and (width <= 767px) {
  .faq > .ctn-main {
    padding: 50px 20px;
  }
}

.accordion-wrapper {
  margin-top: 60px;
}

.accordion-wrapper ul {
  margin: 0;
}

.accordion-wrapper li {
  margin-left: 0;
}

.accordion-wrapper .accordion {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 0 20px;
  list-style-type: none;
  box-shadow: 0 0 4px #0000002e;
}

.accordion-wrapper .accordion-title {
  align-items: center;
  padding: 20px 0;
  font-family: Maxx-Regular, Maxx-Medium, Maxx-Bold, HelveticaNeue-Bold, helvetica, sans-serif;
  display: -webkit-flex;
  display: flex;
}

.accordion-wrapper .accordion-button-ctn {
  text-align: right;
  width: 75px;
  margin-left: auto;
  display: inline-block;
  position: relative;
}

.accordion-wrapper .accordion-button-ctn:before, .accordion-wrapper .accordion-button-ctn:after {
  content: "";
  background-color: #e2272f;
  border-radius: 4px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: calc(100% - 22px);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.accordion-wrapper .accordion-button-ctn:before {
  width: 4px;
  height: 22px;
  margin-top: -11px;
  margin-left: -2px;
}

.accordion-wrapper .accordion-button-ctn:after {
  width: 22px;
  height: 4px;
  margin-top: -2px;
  margin-left: -11px;
}

.accordion-wrapper .content {
  padding-bottom: 20px;
  display: none;
}

.accordion-wrapper .content p, .accordion-wrapper .content ul {
  margin-left: 28px;
  font-size: 1.6rem;
  line-height: 24px;
}

.accordion-wrapper .accordion.expanded .accordion-button-ctn:before, .accordion-wrapper .accordion.expanded .accordion-button-ctnafter {
  transition: all .2s ease-in-out;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordion-wrapper .accordion.expanded .accordion-button-ctn:after {
  opacity: 0;
}

.accordion-wrapper .container-btn {
  cursor: pointer;
}

.site-footer {
  color: #fff;
  background-color: #c8102e;
  padding-top: 41px;
  padding-bottom: 39px;
  font-size: 1.2rem;
  line-height: 28px;
}

@media only screen and (width <= 900px) {
  .site-footer {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.site-footer .ctn-main {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 13px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width >= 1039px) {
  .site-footer .ctn-main {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.site-footer__social a {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding-top: 1px;
  padding-left: 1px;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (width <= 900px) {
  .site-footer__social a {
    margin: 0 3px;
  }
}

.site-footer__social a:hover {
  background-color: #000;
}

.site-footer__social a[name="tiktok"] img {
  max-width: 24px;
}

.site-footer__social img {
  display: block;
}

.site-footer__nav, .site-footer__legal {
  margin-top: 13px;
  font-size: 1.6rem;
  line-height: 35px;
}

@media only screen and (width <= 900px) {
  .site-footer__nav, .site-footer__legal {
    font-size: 1.1rem;
  }
}

.site-footer__nav {
  align-self: flex-end;
}

@media only screen and (width <= 900px) {
  .site-footer__nav {
    width: 100%;
  }
}

.site-footer__nav li {
  display: inline-block;
}

.site-footer__nav a {
  color: #fff;
  padding: 0 8px;
  text-decoration: underline;
  transition: color .5s;
}

.site-footer__nav a:hover {
  color: #000;
}

.site-footer__legal {
  text-align: right;
  max-width: 300px;
  line-height: 1em;
}

@media only screen and (width <= 1259px) {
  .site-footer__legal {
    text-align: left;
    max-width: none;
  }
}

.site-footer__legal p {
  margin: 0;
}

.site-footer__legal a.optanon-show-settings {
  color: #fff;
}

.site-footer__right {
  width: 40%;
}

@media only screen and (width <= 900px) {
  .site-footer__right {
    width: 100%;
  }
}

button, input, select, textarea {
  vertical-align: baseline;
  margin: 0;
  font-size: 100%;
}

.site-search input[type="search"] {
  visibility: hidden;
  float: right;
  width: 1px;
}

.form-unit {
  width: 100%;
  margin-bottom: 13px;
}

.form-unit:after {
  content: "";
  clear: both;
  display: table;
}

select.bits-FormControl, input.bits-FormControl, textarea.bits-FormControl {
  border: 0;
  border-radius: 0;
  height: 38px;
}

textarea.bits-FormControl {
  height: auto;
}

.bits-FormControl.btn-submit {
  color: #fff;
  background-color: #d12a2f;
}

select[disabled] {
  color: gray;
  font-style: italic;
}

select {
  color: #fff;
  background-color: #fff;
  border-color: #cbcbcb;
  border-radius: 0;
  height: 30px;
  font-size: 1.8px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::placeholder {
  font-style: italic;
}

::-webkit-input-placeholder {
  color: gray;
}

::placeholder {
  color: gray;
}

:-ms-input-placeholder {
  color: gray;
}

input:-moz-placeholder {
  color: gray;
}

:focus::-webkit-input-placeholder {
  color: #0000;
}

#loading {
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  margin: 50px 50%;
  display: none;
}

form > .ctn-main {
  background-color: #fff;
  padding: 58px 70px;
}

form h4 {
  margin-bottom: 15px;
}

.form-section {
  background-color: #f8f8f8;
}

.form-section .button {
  margin-top: 100px;
  margin-bottom: 76px;
}

@media only screen and (width <= 1000px) {
  .form-section .button {
    margin: 48px 0;
  }

  .form-section .ctn-main {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 30px 20px 0;
  }
}

.form-header .text-ctn p {
  margin-bottom: 0;
}

.question-ctn {
  margin-top: 40px;
  transition: all 2s;
}

@media only screen and (width <= 1000px) {
  .question-ctn {
    margin-top: 10px;
  }
}

.question-ctn h3 {
  margin-bottom: 20px;
}

.question-ctn.required-alert {
  background: #ebc3c3;
  box-shadow: 0 0 0 15px #ebc3c3;
}

.form-header + .question-ctn {
  margin-top: 56px;
}

@media only screen and (width <= 1000px) {
  .form-header + .question-ctn {
    margin-top: 10px;
  }
}

.e_alert-mind p, .e_alert-body p, .e_alert-community p {
  color: #c55555;
}

@media only screen and (width <= 1000px) {
  .form-header + .question-ctn {
    margin-top: 0;
  }
}

.error-404 .content.ctn-main {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 273px);
  display: -webkit-flex;
  display: flex;
}

.terms .content {
  margin-top: 80px;
  margin-bottom: 80px;
}

.faq h3 {
  margin-top: 60px;
}

h3 + .accordion-wrapper {
  margin-top: 20px;
}

.no-wrap {
  white-space: nowrap;
}

.gtm-iframe {
  visibility: hidden;
  display: none;
}

@media print {
  * {
    color: #000 !important;
  }

  body {
    background-color: #fff;
    font-size: 12pt;
  }

  img {
    max-width: 300px;
  }

  .site-content {
    padding-bottom: 300px;
    overflow: visible;
  }

  .box {
    max-width: none !important;
    position: static !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .img-ctn {
    position: static !important;
  }

  .steps .col-1 {
    page-break-inside: avoid !important;
  }

  .steps .acc-title {
    display: none;
  }

  .steps .acc-list {
    display: block !important;
  }

  :after, :before {
    display: none !important;
  }

  .block {
    margin-bottom: 50px !important;
    display: block !important;
  }

  .text-ctn, .col-ctn {
    width: 100% !important;
    display: block !important;
  }

  .content-area + .widget-area, .widget-tide, header, footer {
    display: none !important;
  }

  .site-footer, .print-buttons, .button {
    display: none;
  }
}

body.printing .text-centered {
  text-align: left !important;
}
/*# sourceMappingURL=index.b506b7f6.css.map */
